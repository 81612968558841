import * as serviceWorker from './serviceWorker';
import "babel-polyfill"
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, Router } from 'react-router-dom'
import App from './App';
import PageError from './components/PageError';
import Clients from './components/Clients';
import Contact from './components/Contact';
import Services from './components/Services';
import './styles/bootstrap.min.css';
import './styles/contact.css';
import './styles/contactform.css';
import './styles/footer.css';
import './styles/header.css';
import './styles/home.css';
import './styles/index.css';
import './styles/services.css';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import ReactPixel from 'react-facebook-pixel';

// Pixel setup
const options = {
  autoConfig: true, 	// set pixel's autoConfig
  debug: false, 		// enable logs
};
//Pixel init
ReactPixel.init('1507787882696648', options);

const trackingId = "UA-141246310-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

const history = createBrowserHistory();
ReactGA.set({ page: history.location });
ReactGA.pageview(history.location.pathname);
ReactPixel.pageView(history.location.pathname);

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
  ReactPixel.pageView(location.pathname);
});

const routing = (
  <Router history={history}>
    <div>
      <Switch>
        <Route exact path="/" component={App} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/clients" component={Clients} />
        <Route exact path="/services" component={Services} />
        <Route path='*' exact={true} component={PageError} />
      </Switch>
    </div>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
