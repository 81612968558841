import React, { Component } from 'react';
import CrossfadeImage from 'react-crossfade-image'

class GridImage extends Component {
    render() {
        return (
            <div>
                <div className="img-responsive gallery_product_img info-image">
                    <CrossfadeImage rel={"preload"} src={`./images/portfolio/${this.props.filename}`} duration={3800} timingFunction={"ease"} />
                </div>
                <div className="middle info-image">
                    <div className="img-overlay">
                        <p className="img-text">
                            <strong>{this.props.comment.company}</strong><br />
                            <span className="font-light font-small">
                                {this.props.comment.catagory}<br />
                                {this.props.comment.subcatagory['1'] && this.props.comment.subcatagory['1']}<br />
                                {this.props.comment.subcatagory['2'] && this.props.comment.subcatagory['2']}{this.props.comment.subcatagory['2'] && <br />}
                                {this.props.comment.subcatagory['3'] && this.props.comment.subcatagory['3']}{this.props.comment.subcatagory['3'] && <br />}
                                {this.props.comment.subcatagory['4'] && this.props.comment.subcatagory['4']}{this.props.comment.subcatagory['4'] && <br />}
                                {this.props.comment.subcatagory['5'] && this.props.comment.subcatagory['5']}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default GridImage;