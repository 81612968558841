import React, { Component } from 'react';
import ContactForm from './ContactForm';

class ServicesPage extends Component {
    render() {
        return (
            <div className="container-fluid bg-secondary">
                <div className="container">
                    <div className="row row-grid pr-0 services-right">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-0 text-secondary services-right pr-5 d-flex flex-column">
                            <p className="pr-5 services-right font-light">
                                With a diverse pool of talented creatives, Lawtz Design Group is able to offer a range of graphic design services.
                            </p>
                            <p className="font-light">
                                <h2>Branding</h2>
                                Our comprehensive branding services focus on the impression your business sends to your prospective and existing customers. We aim to provide a visually striking persona to your brand. We want your brand to connect with your audience and will always concentrate on forging a unique identity for your company.
                            </p>
                            <p className="font-light">
                                Brand continuity is extremely important and we’ll ensure that your customers gain a familiarity with your company which keeps them comfortable and engaged. Our team of highly talented designers, illustrators and project managers will apply their trade to supply the real personality of your business. We cover every angle of branding that you require.
                            </p>
                            <p className="font-light">
                                <h2>Design</h2>
                                We can assist with all visual aspects of design and creation ranging from brand identity and full rebranding packages to logo design, catalogues, brochures, magazines and newsletters. You name it, we design it. Our aim is to clearly communicate your message through your marketing collateral and ensure that your customers engage.
                            </p>
                            <p className="font-light">
                                <h2>Digital</h2>
                                We’re not just all about print design, we can support the digital aspects of your company too, from Powerpoint presentations to website design, build and SEO optimisation.
                            </p>
                            <p className="font-light pb-3">
                                <strong>Get in touch today for a free consultation via our Live Chat feature or our enquiry form below.</strong>
                            </p>
                            <ContactForm rows={16}/>
                        </div>
                        <div className="col-lg-3 offset-lg-1 col-md-3 offset-md-1 col-sm-3 offset-sm-1 col-xs-3 offset-xs-1 services-right services-images pl-0 mb-0">
                            <div>
                                <img src="./images/QBBF-directory.jpg" alt='Client Comments' className="img-responsive gallery_product_img info-image mb-3" />
                            </div>
                            <div>
                                <img src="./images/TEIPposter.jpg" alt='Client Comments' className="img-responsive gallery_product_img info-image mb-3" />
                            </div>
                            <div>
                                <img src="./images/CDnewspaper.jpg" alt='Client Comments' className="img-responsive gallery_product_img info-image mb-3" />
                            </div>
                            <div>
                                <img src="./images/GLstationary.jpg" alt='Client Comments' className="img-responsive gallery_product_img info-image mb-1" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ServicesPage;
