import React, { Component } from 'react';
import Footer from './Footer';
import Header from './Header';
import ContactPage from './ContactPage';
// import ReactPixel from 'react-facebook-pixel';

class Contact extends Component {
  // componentDidMount() {
  //   const options = {
  //     autoConfig: true, 	// set pixel's autoConfig
  //     debug: false, 		// enable logs
  //   };
  //   ReactPixel.init('981631762175095', options);
  //   ReactPixel.pageView();
  //   ReactPixel.track('Contact Page', { test: "test" })
  // }
  render() {
    return (
      <div>
        <Header />
        <ContactPage />
        <Footer />
      </div>
    );
  }
}

export default Contact;
