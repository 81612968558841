import React, { Component } from 'react';
import ReactGA from 'react-ga';
const firebase = require('firebase/app');
require('firebase/database');
const trackingId = "UA-141246310-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);



var config = {
    apiKey: 'AIzaSyBU-obHhG1nldvHxpM-2W33GXXelzVFsfU',
    authDomain: 'lawtz-design-group.firebaseapp.com',
    databaseURL: 'lawtz-design-group.firebaseio.com',
    projectId: 'lawtz-design-group'
}

firebase.initializeApp(config);


class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailSent: false
        }
    }

    clearForm = () => { 
        document.getElementById("contactForm").reset();
      }

    sendEmail = (e) => {
        e.preventDefault();

        ReactGA.event({
            category: 'Contact',
            action: 'Contact form submitted',
            label: 'Contact form',
            nonInteraction: false
          });

        firebase.database().ref('/messages').push({
            name: e.target.name.value,
            telephone: e.target.telephone.value,
            message: e.target.message.value,
            email: e.target.email.value
        });
        this.setState(() => ({
            emailSent: true
        }));
        this.clearForm();
    }

    render() {
        return (
            <div className="form-div flex-column d-flex item-grow">
                <form onSubmit={this.sendEmail} id="contactForm" className="item-grow flex-column d-flex">
                    {this.state.emailSent && <div className="alert">Your message has been sent.</div>}
                    <input className="form-text" type='text' name="name" id="name" placeholder='Name'></input>
                    <input className="form-text" type='text' name="email" id="email" placeholder='Email'></input>
                    <input className="form-text" type='text' name="telephone" id="telephone" placeholder='Telephone'></input>
                    <textarea className="form-text" name="message" id="message" placeholder='Enquiry' rows={this.props.rows}></textarea>
                    <div style={{ padding: 0, margin: 0 }} className="row item-grow">
                        <button style={{ height: 100 + "%" }} className="form-btn">Send Enquiry</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default ContactForm;