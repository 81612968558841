import React, { Component } from 'react';

class Footer extends Component {

    render() {
        return (
            <div className="container-fluid bg-primary">
                <div className="container">
                    <div className="row footer">
                        <div className="col-md-5 logo pt-5">
                            <img src="./images/logo.png" width="195" alt="Main Logo"></img>
                            <p className="footer-greeting font-light font-14 pt-3 pr-5">Hello. We are Lawtz Design Group, a small group of graphic design creatives, based in Devon, UK. We design because it is what we love to do. We are passionate about helping our clients to make the best first impression they can. Contact us if you’d like a friendly chat about your company or advice relating to design.</p>
                            <p className="font-14">
                                <strong>Contact us</strong><br />
                                <strong>Mob </strong>+44 (0) 7599 429125<br />
                                <strong>Email: </strong><span className="email-link"><a href="mailto:info@lawtzdesign.com?subject=Website%20Enquiry">info@lawtzdesign.com</a></span>
                            </p>
                        </div>
                        <div className="col-md-2 offset-md-1 pt-5">
                            <p className="font-light font-14 footer-text mb-0" style={{paddingBottom: 19.5 + "px"}}>
                                <h2 style={{fontWeight: 600, fontSize: 14}}>Branding</h2>
                                Logo<br />
                                Brand Guidelines<br />
                                Business Cards<br />
                                Clothing<br />
                                Compliment Slips<br />
                                Envelopes<br />
                                Letterheads<br />
                                Merchandise Branding<br />
                                Packaging<br />
                                Promotional Items<br />
                                Signage<br />
                                Stationary Packs<br />
                                Vehicle Livery
                            </p>
                            <p className="font-light font-14 footer-text">
                            <h2 style={{fontWeight: 600, fontSize: 14}}>Print</h2>
                                Annual Reports<br />
                                Advertising<br />
                                Banners<br />
                                Billboards<br />
                                Book Layout
                            </p>
                        </div>
                        <div className="col-md-2 pt-5 footer-column">
                            <p className="font-light font-14 footer-text">
                                Brochures<br />
                                Catalogues<br />
                                Direct Mail<br />
                                Directories<br />
                                Event Guides<br />
                                Exhibition Panels<br />
                                Flyers<br />
                                Illustration<br />
                                Investor Packs<br />
                                Invitations<br/>
                                Labels<br />
                                Large Format Posters<br />
                                Leaflets<br />
                                Location Maps<br />
                                Loyalty Cards<br />
                                Magazines<br />
                                Magazine Adverts<br />
                                Menus<br />
                                Newsletters<br />
                                Newspaper Adverts<br />
                                Plans
                            </p>
                        </div>
                        <div className="col-md-2 pt-5 footer-column">
                            <p className="font-light font-14 footer-text mb-0" style={{paddingBottom: 19.5 + "px"}}>
                            Point of Sale<br />
                            Postcards<br />
                            Posters<br />
                            Presentation Folders<br />
                            Product Packaging<br />
                            Programmes<br />
                            Roller Banners<br />
                            Timetables<br />
                            Wedding Invitations<br />
                            </p>
                            <p className="font-light font-14 footer-text" style={{paddingBottom: 19.5 + "px"}}>
                            <h2 style={{fontWeight: 600, fontSize: 14}}>Digital</h2>
                                Animated Videos<br />
                                Facebook Headers<br />
                                Infographics<br />
                                Introduction Videos<br />
                                <span style={{fontSize:13+"px"}}>Powerpoint Presentations</span><br />
                                SEO<br />
                                Social Media Adverts<br />
                                Twitter Headers<br />
                                Website Design<br />
                                Website Build<br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
