import React, { Component } from 'react';
import ContactForm from './ContactForm';

class ContactPage extends Component {
    render() {
        return (
            <div className="container-fluid bg-secondary">
                <div className="container">
                    <div className="row row-grid pr-0">
                        <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7 pl-0 pb-0 mb-0 text-secondary d-flex flex-column">
                            <p className="font14"><strong>Contact us for a no obligation consultation</strong></p>
                            <ContactForm rows={18} />
                        </div>
                        <div className="col-lg-4 offset-lg-1 col-md-4 offset-md-1 col-sm-4 offset-sm-1 col-xs-6 offset-xs-1 pl-0 pb-0 mb-0">
                            <div className="contactimages float-right">
                                <img src="./images/QBBF-directory.jpg" alt='Client Comments' style={{ width: 80 + "%" }} className="img-responsive float-right gallery_product_img info-image mb-3" />
                            </div>
                            <div>
                                <img src="./images/TEIPposter.jpg" alt='Client Comments' style={{ width: 80 + "%" }} className="img-responsive float-right gallery_product_img info-image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactPage;
