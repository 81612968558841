import React, { Component } from 'react';
import Footer from './Footer';
import Header from './Header';
import ServicesPage from './ServicesPage';
// import ReactPixel from 'react-facebook-pixel';

class Services extends Component {
//   componentDidMount() {
//     const options = {
//         autoConfig: true, 	// set pixel's autoConfig
//         debug: false, 		// enable logs
//     };
//     ReactPixel.init('981631762175095', options);
//     ReactPixel.pageView();
//     ReactPixel.track('Services Page', {test:"test"})
// }
  render() {
    return (
      <div>
        <Header />
        <ServicesPage />
        <Footer />
      </div>
    );
  }
}

export default Services;
