import React, { Component } from 'react';
// import ReactPixel from 'react-facebook-pixel';


class Client extends Component {
    // componentDidMount() {
    //     const options = {
    //         autoConfig: true, 	// set pixel's autoConfig
    //         debug: false, 		// enable logs
    //     };
    //     ReactPixel.init('981631762175095', options);
    //     ReactPixel.pageView();
    //     ReactPixel.track('Client Page', {test:"test"})
    // }

    render() {
        return (
            <div className="container-fluid bg-secondary">
                <div className="container">
                    <div className="row row-grid pr-0">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3">
                            <div>
                                <img src="./images/Orestone-logo.JPG" alt='Client Comments' className="img-responsive gallery_product_img info-image" />
                            </div>
                            <div>
                                <p className="text-secondary text-center pt-3">
                                    <h2>Orestone Wealth Management</h2>
                                    <span className="font-light">“We have used Lawtz Design for many years. Si is prompt and professional and we are constantly surprised by the level of his work and creativity. We would have no hesitation in recommending Lawtz Design Group”</span><br />
                                    Adrian Howard<br />Director
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3">
                            <div>
                                <img src="./images/DESS-logo.JPG" alt='Client Comments' className="img-responsive gallery_product_img info-image" />
                            </div>
                            <div>
                                <p className="text-secondary text-center pt-3">
                                    <h2>Doha English Speaking School</h2>
                                    <span className="font-light">“LDG are a pleasure to work with. We have worked with them for 4 years and are very happy with all the work that has been produced for us including branding and design work. They are creative, reliable, and have excellent communications.”</span><br />
                                    Alison Soltanifar<br />Marketing Manager
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3">
                            <div>
                                <img src="./images/Eden-Logo.JPG" alt='Client Comments' className="img-responsive gallery_product_img info-image" />
                            </div>
                            <div>
                                <p className="text-secondary text-center pt-3">
                                    <h2>Eden Renewables</h2>
                                    <span className="font-light">“Lawtz Design Groups cordial communication is helpful in discussing potential roadblocks, giving suggestions where necessary, highly recommended”</span><br />
                                    Alec Greenwell<br />Development Manager
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3">
                            <div>
                                <img src="./images/QBBF-logo.jpg" alt='Client Comments' className="img-responsive gallery_product_img info-image" />
                            </div>
                            <div>
                                <p className="text-secondary text-center pt-3">
                                    <h2>Qatar British Business Forum</h2>
                                    <span className="font-light">“We have worked on two large projects with LDG and their attentiveness to detail was exemplary”</span><br />
                                    Kelly Steele<br />Office Manager
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3">
                            <div>
                                <img src="./images/wbw-logo.jpg" alt='Client Comments' className="img-responsive gallery_product_img info-image" />
                            </div>
                            <div>
                                <p className="text-secondary text-center pt-3">
                                    <h2>WBW Solicitors</h2>
                                    <span className="font-light">“Lawtz Design Group are so responsive to our queries &amp; produce great design - nothing is too much trouble.”</span><br />
                                    Jessica De Cruz<br />Marketing Manager
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3">
                            <div>
                                <img src="./images/charlesdarrow-logo.jpg" alt='Client Comments' className="img-responsive gallery_product_img info-image" />
                            </div>
                            <div>
                                <p className="text-secondary text-center pt-3">
                                    <h2>Charles Darrow</h2>
                                    <span className="font-light">“A fantastic company who’s business model is a refreshing change to other agencies. Great service &amp; superb value.”</span><br />
                                    Jon Clyne<br />Director
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3">
                            <div>
                                <img src="./images/whatson-logo.jpg" alt='Client Comments' className="img-responsive gallery_product_img info-image" />
                            </div>
                            <div>
                                <p className="text-secondary text-center pt-3">
                                    <h2>What’s On Publications</h2>
                                    <span className="font-light">“LDG design is of the highest standard and we would recommend them to anyone looking for excellent design and efficiency”</span><br />
                                    Jamie Sinclair<br />Editor
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3">
                            <div>
                                <img src="./images/MacWhirter-logo.JPG" alt='Client Comments' className="img-responsive gallery_product_img info-image" />
                            </div>
                            <div>
                                <p className="text-secondary text-center pt-3">
                                    <h2>MacWhirter Western Ltd</h2>
                                    <span className="font-light">“At Macwhirter we often require design at late notice and Lawtz Design have never let us down.  It is refreshing to find such a reliable creative company and we’d recommend trying them”</span><br />
                                    Paul Froude<br />Director
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3">
                            <div>
                                <img src="./images/SEDHR-logo.jpg" alt='Client Comments' className="img-responsive gallery_product_img info-image" />
                            </div>
                            <div>
                                <p className="text-secondary text-center pt-3">
                                    <h2>SEDHRP</h2>
                                    <span className="font-light">“Expert attention to detail, fast turnaround and a pleasure to work with – this is why LDG are our go-to company”</span><br />
                                    Neil Harris<br />Habitat Regulations Delivery Manager
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Client;
