import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Footer from './Footer';
import Header from './Header';


class PageError extends Component {
    render() {
        return (
            <div>
                <Header />
                <div className="container-fluid bg-secondary">
                    <div className="container">
                        <div className="row row-grid pr-0">
                            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 pl-0 mb-3 img-container text-primary font-light">
                                <h1>Oops...</h1>
                                <p>We couldnt find the page you were looking for.  This is either because:</p>
                                <ul>
                                    <li>There is an error in the URL entered into your web browser. Please check the URL and try again.</li>
                                    <li>The page you are looking for has been moved or deleted.</li>
                                </ul>
                                <p>You can return to our homepage by clicking <span><Link to={`/`} style={{color: '#3d3f3d'}} activeStyle={{color: 'red'}}>here</Link></span>, or you can try searching for the content you are seeking by clicking here.</p>

                                <p><strong>Email</strong><br />
                                    info@lawtzdesign.com</p>

                                <p><strong>Phone</strong><br />
                                    01626 832107</p>

                                <p><strong>Mobile</strong><br />
                                    07599 429125</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default PageError;
