import React, { Component } from 'react';
import GridImage from './GridImage';
// import ReactPixel from 'react-facebook-pixel';

class Home extends Component {
    // componentDidMount() {
    //     const options = {
    //         autoConfig: true, 	// set pixel's autoConfig
    //         debug: false, 		// enable logs
    //     };
    //     ReactPixel.init('981631762175095', options);
    //     ReactPixel.pageView();
    //     ReactPixel.track('Home Page', {test:"test"})
    // }
    constructor(props) {
        super(props)
        this.state = {
            gridSize: 9,
            allImages: [
                {
                    filename: "portfolio_001.JPG",
                    comment: {
                        company: "PREMIER INN",
                        catagory: "Print Advertising",
                        subcatagory: {
                            1: "Digital Advertising",
                        }
                    }
                },
                {
                    filename: "portfolio_002.JPG",
                    comment: {
                        company: "ORESTONE WEALTH MANAGEMENT",
                        catagory: "Branding",
                        subcatagory: {
                            1: "Presentation Folders",
                            2: "Merchandise",
                            3: "Stationery",
                        }
                    }
                },
                {
                    filename: "portfolio_003.JPG",
                    comment: {
                        company: "RE:FUEL",
                        catagory: "Branding",
                        subcatagory: {
                            1: "Label Design"
                        }
                    }
                },
                {
                    filename: "portfolio_004.JPG",
                    comment: {
                        company: "THE EXPERTS IN PROPERTY",
                        catagory: "Event Promotion",
                        subcatagory: {
                            1: "Posters",
                            2: "Flyers"
                        }
                    }
                },
                {
                    filename: "portfolio_005.JPG",
                    comment: {
                        company: "CHARLES DARROW",
                        catagory: "Promotion",
                        subcatagory: {
                            1: "Direct Mail",
                            2: "Newspaper Advertising",
                            3: "Leaflets"
                        }
                    }
                },
                {
                    filename: "portfolio_006.JPG",
                    comment: {
                        company: "QATAR BRITISH BUSINESS FORUM",
                        catagory: "Event Branding",
                        subcatagory: {
                            1: "Event Programme",
                            2: "Stage Backdrop",
                            3: "Tickets",
                            4: "Posters",
                            5: "Powerpoint Presentation"
                        }
                    }
                },
                {
                    filename: "portfolio_007.JPG",
                    comment: {
                        company: "ORESTONE WEALTH MANAGEMENT",
                        catagory: "Branding",
                        subcatagory: {
                            1: "Presentation Folders",
                            2: "Merchandise",
                            3: "Stationery"
                        }
                    }
                },
                {
                    filename: "portfolio_008.JPG",
                    comment: {
                        company: "WBW SOLICITORS",
                        catagory: "Print Branding",
                        subcatagory: {
                            1: "Promotion",
                            2: "Newspaper Advert",
                            3: "Flyers",
                            4: "Digital Branding"
                        }
                    }
                },
                {
                    filename: "portfolio_009.JPG",
                    comment: {
                        company: "QATAR BRITISH BUSINESS FORUM",
                        catagory: "Event Branding",
                        subcatagory: {
                            1: "Event Programme",
                            2: "Stage Backdrop",
                            3: "Tickets",
                            4: "Posters",
                            5: "Powerpoint Presentation"
                        }
                    }
                },
                {
                    filename: "portfolio_010.JPG",
                    comment: {
                        company: "MICHELMORES",
                        catagory: "Digital Promotion",
                        subcatagory: {
                            1: "Infographic"
                        }
                    }
                },
                {
                    filename: "portfolio_011.JPG",
                    comment: {
                        company: "WOODS ESTATE AGENTS",
                        catagory: "Marketing",
                        subcatagory: {
                            1: "Brochures",
                            2: "Website Banners",
                            3: "Adverts"
                        }
                    }
                },
                {
                    filename: "portfolio_012.JPG",
                    comment: {
                        company: "FROZEN IN MOTION",
                        catagory: "Website UI Design",
                        subcatagory: {
                            1: "Digital Advertising"
                        }
                    }
                },
                {
                    filename: "portfolio_013.JPG",
                    comment: {
                        company: "WHAT’S ON SOUTHWEST",
                        catagory: "Magazine Design",
                        subcatagory: {
                            1: "Digital Branding",
                            2: "Digital Advertising"
                        }
                    }
                },
                {
                    filename: "portfolio_014.JPG",
                    comment: {
                        company: "WEBB ENTERPRISES",
                        catagory: "Branding",
                        subcatagory: {
                            1: "Menu",
                            2: "Advertising",
                            3: "Merchandise"
                        }
                    }
                },
                {
                    filename: "portfolio_015.JPG",
                    comment: {
                        company: "BRADLEYS ESTATE AGENTS",
                        catagory: "Promotional Branding",
                        subcatagory: {
                            1: "Large Format Posters ",
                            2: "Point of Sale Posters",
                            3: "Flyers"
                        }
                    }
                }
            ],
            displayingImages: [],
            filenames: []
        }
    }

    componentDidMount() {
        let firstImages = [];
        let filenames = [];
        // for (let i = 0; i < this.state.gridSize; i++) {
        //     const rand = this.generateRandomImage();
        //     if (filenames.includes(rand.filename)) {
        //         i = i - 1;
        //     } else {
        //         filenames.push(rand.filename);
        //         firstImages.push(rand);
        //     }
        // }

        for (let i = 0; i < this.state.gridSize; i++) {
            filenames.push(this.state.allImages[i].filename);
            firstImages.push(this.state.allImages[i]);
        }


        this.setState(() => ({
            displayingImages: firstImages,
            filenames
        }));

        //set interval and stop after a number of rotations
        let timesRun = 0;
        const rotations = 20

        const interval = setInterval(() => {
            timesRun += 1;
            if (timesRun === rotations) {
                clearInterval(interval);
            }
            this.selectNextImage();
        }, 3000);
    }

    selectNextImage = () => {
        //Get next image
        let nextImage = "";
        for (let i = 0; i < 1; i++) {
            const rand = this.generateRandomImage();
            if (this.state.filenames.includes(rand.filename)) {
                i = i - 1;
            } else {
                nextImage = rand;
            }
        }
        // //Select random image position
        const position = Math.floor(Math.random() * this.state.gridSize);

        //get the current arrays from state
        let currentDisplay = this.state.displayingImages.slice(0);
        let filenames = this.state.filenames.slice(0);

        filenames[position] = nextImage.filename;
        currentDisplay[position] = nextImage;


        this.setState(() => (
            {
                displayingImages: currentDisplay,
                filenames
            }
        ));

    }

    generateRandomImage = () => {
        return this.state.allImages[Math.floor(Math.random() * this.state.allImages.length)];
    }

    render() {
        return (
            <div>
                {
                    this.state.displayingImages.length > 0 ?
                        <div className="container-fluid bg-secondary">
                            <div className="container">
                                <div className="row row-grid pr-0">
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3 img-container">
                                        <GridImage filename={this.state.filenames[0]} comment={this.state.displayingImages[0].comment} />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3 img-container">
                                        <GridImage filename={this.state.filenames[1]} comment={this.state.displayingImages[1].comment} />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3 img-container">
                                        <GridImage filename={this.state.filenames[2]} comment={this.state.displayingImages[2].comment} />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3 img-container">
                                        <GridImage filename={this.state.filenames[3]} comment={this.state.displayingImages[3].comment} />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3 img-container">
                                        <GridImage filename={this.state.filenames[4]} comment={this.state.displayingImages[4].comment} />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3 img-container">
                                        <GridImage filename={this.state.filenames[5]} comment={this.state.displayingImages[5].comment} />
                                    </div>
                                    {this.state.gridSize > 6 &&
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3 img-container">
                                            <GridImage filename={this.state.filenames[6]} comment={this.state.displayingImages[6].comment} />
                                        </div>
                                    }
                                    {this.state.gridSize > 6 &&
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3 img-container">
                                            <GridImage filename={this.state.filenames[7]} comment={this.state.displayingImages[7].comment} />
                                        </div>
                                    }
                                    {this.state.gridSize > 6 &&
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6 pl-0 mb-3 img-container">
                                            <GridImage filename={this.state.filenames[8]} comment={this.state.displayingImages[8].comment} />
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        :
                        <div style={{ color: "red" }}>Loading...</div>
                }
            </div>
        );
    }
}

export default Home;
