import React from 'react';
import { Link } from 'react-router-dom'

class Header extends React.Component {
    render() {
        return (
            <div className="container-fluid bg-primary">
                <div className="container">
                    <div className="row header">
                        <div className="col-md-6 logo">
                            <Link to={`/`}><img src="./images/logo.png" width="195" alt="Main Logo"></img></Link>
                        </div>
                        <div className="col-md-6 text-primary no-wrap text-right logo header-flex">
                            <p className="header-text">
                                <br />
                                Mob: <span className="font-light">+44 (0) 7599 429125</span><br />
                                Email: <span className="font-light email-link"><a href="mailto:info@lawtzdesign.com?subject=Website%20Enquiry">info@lawtzdesign.com</a></span></p>
                            <p className="links-text">
                                <span className="links-text "><Link to={`/`} style={{color: 'white'}}>HOME</Link></span>
                                <span className="links-text"><Link to={`/clients`} style={{color: 'white', bottom: 0+'px'}}>CLIENTS</Link></span>
                                <span className="links-text"><Link to={`/services`} style={{color: 'white'}}>SERVICES</Link></span>
                                <span className="links-text-right"><Link to={`/contact`} style={{color: 'white'}}>CONTACT</Link></span></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;

